.slider-container {
  width: 65%;
  padding: 2%;
  margin: 0 3%;
  transform: scale(1); /* you need a scale here to allow it to transition in both directions */
  transition: 0.15s all ease;
  padding-bottom: 6%;
  padding-top: 6%;
}

.slider-active {
  padding: 0;
  padding-top: 4%;
  margin: 0 5%;
}