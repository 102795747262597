:root {
  --ifm-color-emphasis-300: #d9d9d9;
  --ifm-font-color-base: #000000;
  --ifm-color-primary: #345c44;
  --accordion-background: #f9f9f9;
}

.accordion {
  margin-bottom: 1.5rem;
  width: 100%;
}

.item {
  border-bottom: 1px solid var(--ifm-color-emphasis-300);
  border-top: 1px solid var(--ifm-color-emphasis-300);
  border-top-width: 0;
}

.item:first-of-type {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.item:last-of-type {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
}

.item:first-of-type .button {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.item:last-of-type .button:not(.buttonExpanded) {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding-left: 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  color: var(--ifm-font-color-base);
  background-color: transparent;
  border: none;
  transition: background-color 0.25s ease-in-out;
}

.buttonExpanded {
  composes: button;
  color: var(--ifm-color-primary);
  background-color: var(--accordion-background);
}

.button:disabled {
  box-shadow: none;
  cursor: auto;
  color: #808080;
}

.button:focus {
  position: relative;
  outline: none;
}

.button:focus-visible {
  box-shadow: 0 0 0 3px var(--ifm-color-primary);
}

.chevron {
  flex-shrink: 0;
  margin-left: auto;
  transition: transform 0.15s cubic-bezier(0, 0, 0, 1);
}

.buttonExpanded .chevron {
  transform: rotate(180deg);
}

.content {
  transition: height 0.15s cubic-bezier(0, 0, 0, 1);
  background-color: var(--accordion-background);
}

.panel {
  padding-left: 1.25rem;
  padding-bottom: 10px;
}